import React, { useEffect, useRef, useState } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import InfoSection from "../components/terms-of-use/InfoSection";
import "../assets/scss/terms-of-use/index.scss";

const PgTOU = () => {
  const [currentLocale, setCurrentLocale] = useState(null);
  const [isHiddenNavigation, setIsHiddenNavigation] = useState(false);
  const refWhyNotAvailableBlock = useRef(null);
  const LOCALIZATION_SWITCH = process.env.LOCALIZATION_SWITCH === "true";

  useEffect(() => {
    setIsHiddenNavigation(
      localStorage.getItem("availability-status") === "true"
    );
  }, []);

  return (
    <Layout
      bgHeader="#fff"
      setLocale={setCurrentLocale}
      isHiddenNavigation={isHiddenNavigation}
    >
      <Seo
        title="Terms Of Use | AMZScout"
        description="Please read these Terms carefully. By using amzsc.amzgb.com (the “Site”), AMZScout’s software or signing up for an account, you’re agreeing to these Terms. This is a legally binding agreement."
        page="terms-of-use"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <InfoSection
        currentLocale={currentLocale}
        refWhyNotAvailableBlock={refWhyNotAvailableBlock}
        localizationSwitch={LOCALIZATION_SWITCH}
      />
    </Layout>
  );
};

export default PgTOU;
