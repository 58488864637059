import React from "react"

const PG_TOU_INFO_SECTION_CONTENT = {
  us: {
      t1: () => (
      <>
        <p>
          Please read these Terms carefully. By using amzsc.amzgb.com (the{" "}
          <i>“Site”</i>), AMZScout’s software or signing up for an account,
          you’re agreeing to these Terms. This is a legally binding agreement.
          <hr className="PgTOU__hr" />
          AMZScout (<i>“AMZScout”</i> or the <i>“Service”</i>) is a software
          service provider for merchants that is offered through AMZScout’s Site
          that is used for Amazon product research. AMZScout is operated by
          AMZScout Corp., a Pennsylvania corporation (“we,” and/or “us”).
          AMZScout has officers, employees, independent contractors, and
          representatives (the “Team”). As a customer of the Service or as a
          representative of an entity that is a customer of the Service, you're
          a “Member” according to this Agreement. You agree that:
        </p>
      </>
    ),
    t2: () => (
      <>
        <b>Mailing Address:</b>
        <ul className="with-mark">
          <li>
            <b>AMZSCOUT CORPORATION</b>
          </li>
          <li>1735 Market Street, Suite 3750</li>
          <li>Philadelphia, Pennsylvania 19103</li>
        </ul>
      </>
    ),
    t3: () => (
      <>
        <b>Governing Law.</b> This Agreement shall be governed, construed, and
        enforced in accordance with the laws of the{" "}
        <i>Commonwealth of Pennsylvania</i> without regard to its conflict of
        laws rules, or international law or convention.
      </>
    ),
    t4: () => "Termination.",
  },
  eu: {
      t1: () => (
      <>
        <p>
          Please read these Terms carefully. By using amzsc.amzgb.com (the{" "}
          <i>“Site”</i>), AMZScout’s software or signing up for an account,
          you’re agreeing to these Terms. This is a legally binding agreement.
          <hr className="PgTOU__hr" />
          AMZScout (<i>“AMZScout”</i> or the <i>“Service”</i>) is a software
          service provider for merchants that is offered through AMZScout’s Site
          that is used for Amazon product research. AMZScout software and
          services are provided by either AMZScout Corp., a Pennsylvania
          corporation or Growthco DMCC, a company incorporated in United Arab
          Emirates, licence number DMCC-818075 (“we,” and/or “us”). AMZScout has
          officers, employees, independent contractors, and representatives (the
          “Team”). As a customer of the Service or as a representative of an
          entity that is a customer of the Service, you're a “Member” according
          to this Agreement. You agree that:
        </p>
      </>
    ),
    t2: () => (
      <>
        <b>Mailing Address:</b>
        <ul className="with-mark">
          <li>
            <b>AMZScout Corp.</b>
          </li>
          <li>1735 Market Street, Suite 3750</li>
          <li>Philadelphia, Pennsylvania 19103</li>
          <li>
            <b>AMZScout DMCC</b>
          </li>
          <li>Unit No: BA402</li>
          <li>DMCC Business Centre</li>
          <li>Level No 1</li>
          <li>Jewellery & Gemplex 3</li>
          <li>Dubai</li>
          <li>United Arab Emirates</li>
        </ul>
      </>
    ),
    t3: () => (
      <>
        <b>Governing Law.</b> This Agreement shall be governed, construed, and
        enforced in accordance with the laws of{" "}
        <i>England and Wales or the UAE</i> without regard to its conflict of
        laws rules, or international law or convention. Governing law depends on
        the country where the payment was received.
      </>
    ),
    t4: () => "Termination, cancellation, refunds.",
  },
}
export default PG_TOU_INFO_SECTION_CONTENT

